import { func } from "prop-types";
import { State } from "../context/kwitantieContext";
import { decrypt } from "./security";
import { resetToNextMonth } from "./storeUtils";


export const retrieveKwitantieFromLocalStorage = () => {
    const raw = typeof window !== "undefined"
        ? decrypt(localStorage.getItem("state"))
        : undefined

    try {
        return raw ? needNextKwitantie(JSON.parse(raw) as State) : undefined
    } catch {
        return undefined;
    }
}


function needNextKwitantie(state: State) {
    if (!state.lastChange) {
        return state;
    }

    const restoredDate = new Date(state.lastChange * 1000);
    const currentDate = new Date();

    return currentDate.getFullYear() !== restoredDate.getFullYear() || currentDate.getMonth() !== restoredDate.getMonth()
        ? resetToNextMonth(state)
        : state

}